<template>
  <div class="" style="min-height: 150px">
    <div
      style="text-align: justify; overflow-wrap: break-word"
      v-if="!customView"
    >
      <div v-html="html"></div>
    </div>
    <div class="dinamic" v-if="customView">
      <component v-bind:is="customView"></component>
    </div>

    <div class="text-center pt-4">
      <p>
        <button v-on:click="back" class="btn btn-secondary text-uppercase">
          Volver
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "InfoView",
  props: {
    uid: String,
    filtros: Object,
  },
  data() {
    return {
      html: null,
      customView: false,
    };
  },
  methods: {
    back() {
      history.back();
    },
  },
  mounted() {
    if (this.uid.includes("CUMV-"))
      switch (this.uid) {
        case "CUMV-objetivos": {
          this.html = `
          <section>
          <div class="container">
          <h2>Los Objetivos fijados por el Decreto N° 58/21 son:</h2>
    <ol>
        <li>Analizar y evaluar la organización de la Administración Pública Provincial con el objeto de proponer modificaciones estructurales que permitan mejorar su desempeño y elevar la calidad de sus productos y servicios.</li>
        <li>Efectuar los estudios necesarios para diseñar, ejecutar y evaluar la política de recursos humanos del Sector Público.</li>
        <li>Entender en el estudio, implementación y control de las políticas de gestión y de salarios que tiendan a elevar el nivel de eficiencia de los recursos humanos en la Administración Pública Provincial.</li>
        <li>Intervenir en todo acto administrativo que implique modificar la planta de personal, las erogaciones que de ella surjan y la estructura orgánica de las distintas áreas del Poder Ejecutivo.</li>
        <li>Participar, a solicitud de los organismos de la Administración Pública Provincial que lo requieran, en la coordinación de capacitaciones, elaboración de planes y procedimientos y planificación de actividades que le faciliten la gestión de la higiene y seguridad en el trabajo, y la medicina laboral, en el marco de las Leyes Nacionales y Provinciales, normas complementarias, reglamentarias y modificatorias.</li>
        <li>Gestionar el recupero de las prestaciones por Incapacidad Laboral Temporaria (ILT), que de acuerdo a los contratos celebrados con la Aseguradora de Riesgos de Trabajo deben ser reclamados por la Provincia.</li>
        <li>Entender en la planificación e implementación del Plan Provincial de Gobierno Abierto, coordinando las estrategias a desarrollar sobre tecnologías de información, comunicaciones asociadas y otros sistemas electrónicos de tratamiento de la información en la Administración Pública Provincial.</li>
        <li>Entender en la formulación de programas de asistencia a los organismos del Sector Pública Provincial, que así lo requieran, y que tengan por objeto la modernización de la gestión, el mejoramiento continuo de la calidad de sus servicios y la incorporación de nuevas tecnologías de información.</li>
        <li>Entender y definir la implementación de la Política Informática Provincial que involucra a todo el Poder Ejecutivo a través de la definición de estándares tecnológicos y los nuevos sistemas integrados a incorporar.</li>
        <li>Desarrollar las políticas de información económica y estadísticas oficiales, y las actividades de investigación y censos que se realizan en el territorio de la Provincia de San Juan.</li>
        <li>Entender en lo atinente a la Modernización del Estado en todo el ámbito de la Administración Pública Provincial, participando en la gestión de las acciones conducentes a la selección, adquisición e implementación de componentes tecnológicos, con la intervención de otro y otros MInisterios o Secretarías de Estado en el caso que correspondiera.</li>
        <li>Entender en todo lo que compete a la administración de las telecomunicaciones en el ámbito provincial y en todos los requerimientos del área que sean de interés para el Estado Provincial, a saber: servicios de telecomunicaciones, telefonía fija y móvil, redes de voz/datos y enlaces (fibra óptica, radio, satélite, etc.), redes de área local, metropolitana y regional, accesos de internet, nodos de concentración de datos y servicios digitales, data centers (principales y de contingencia) y otras tecnologías afines de interconexión digital.</li>
        <li>Coordinar, convenir y supervisar los acuerdos relativos a telecomunicaciones (en cualquiera de sus formas tecnológicas) con la Nación, Ejecutivo Provincial, Entes Autárquicos dependientes del Ejecutivo Provincial y los Municipios de la Provincia.</li>
        <li>Entender en todo lo que corresponda a estudio, proyecto, supervisión y operación de todas las obras de telecomunicaciones inherentes al área dentro del Ejecutivo Provincial.</li>
        <li>Definir políticas de telecomunicaciones, en el presente y a futuro, que en la materia corresponda determinar.</li>
        <li>Entender en todo lo que compete a la definición y gestión de la seguridad informática del Poder Ejecutivo de la provincia, en lo atinente a la protección de la infraestructura computacional y de la Información contenida en dicha infraestructura.</li>
        <li>Entender en todo lo relativo a la celebración de convenios y relaciones del Poder Ejecutivo con organismos nacionales e internacionales que impliquen y posibiliten el intercambio de información, sistemas integrados de información, tecnologías, redes, telecomunicaciones, metodologías y procedimientos de trabajo y todo otro aspecto inherente a las funciones antes definidas.</li>
    </ol>
          </div>
          </section>
          `;
          break;
        }
      }
    else {
      let params = {};
      if (this.filtros != null && this.filtros != "") {
        params = this.filtros;
      }
      params.uid = this.uid;

      axios
        .post(process.env.VUE_APP_API_URL, params)
        .then((response) => {
          this.html = response.data ? response.data.html : null;
          localStorage.setItem(this.uid, JSON.stringify(response));
        })
        .catch((error) => {
          if (!error.response) {
            console.log("Fuera de Linea");
            if (localStorage.getItem(this.uid)) {
              console.log("Cargando elementos de cache para " + this.uid);
              var resp = JSON.parse(localStorage.getItem(this.uid));
              this.html = resp.data ? resp.data.html : null;
            }
          }
        });
    }
  },
};
</script>
