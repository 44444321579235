<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
  },
  data() {
    return {
      site: 23,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>

<template>
  <section>
    <div class="container">
      <div>
        <h2>Visión</h2>
        <p>
          La Secretaría de Transformación Digital y Modernización del Estado tiene por Visión la de fortalecer
          las capacidades de gestión del Poder Ejecutivo Provincial de San Juan
          orientadas a mejorar las prestación de servicios y productos públicos
          requeridos por la ciudadanía.
        </p>
        <h2>Misión</h2>
        <p>
          La Misión de la Secretaría de Transformación Digital y Modernización del Estado consiste en
          desarrollar herramientas de gestión públicas que permitan a todas las
          organizaciones del Poder Ejecutivo y sus recursos humanos optimizar
          sus procedimientos, incorporar tecnologías de la comunicación y de
          gestión, y promover el desarrollo de su personal orientados a la
          satisfacción de las necesidades de la ciudadanía de forma pertinente.
        </p>

        <a
          href="?uid=CUMV-objetivos"
          class="btn btn-primary btn-lg"
          type="button"
          >Nuestros Objetivos</a
        >
      </div>
    </div>
  </section>
  <div class="container">
    <h2>Organismos</h2>
    <h5>
      La Secretaría de Transformación Digital y Modernización del Estado depende orgánicamente del Ministerio de Economía, Finanzas y Hacienda. <br />Su Estructura está conformada por tres
      Direcciones:
    </h5>
  </div>
  <CardGridComponent
    :columnas="3"
    :titulo="null"
    class="pt-0"
    :data="[
      {
        subtitle: 'Página web',
        target: '_blank',
        title: 'Dirección Provincial de Informática',
        url: 'https://dpi.sanjuan.gob.ar/',
      },
      {
        subtitle: 'Página web',
        target: '_blank',
        title: 'Dirección de Gobierno Digital',
        url: 'https://gobiernodigital.sanjuan.gob.ar/',
      },
      {
        subtitle: 'Página web',
        target: '_blank',
        title: 'Dirección de Conectividad y Ciberseguridad',
        url: 'https://ciberseguridad.sanjuan.gob.ar/',
      },
      
    ]"
  />
  <NewsComponent class="mt-4 mb-3" titulo="Noticias" />
</template>
