<template>
    <div class="container">
    <h2 class="pb-2">{{ titulo != null ? titulo : this.title != null ? this.title : '' }}</h2>
    <div  id="cp_noticias">
        <iframe id="fw-iframe156130" name="fw-iframe156130" title="fw-iframe"
        style="position: relative; min-width: 150px; min-height: 150px; width: 100%; height: 403px;" class="fw-iframe"
        scrolling="no" frameborder="0" data-fw-params="156130" preloader-text="Cargando%E2%80%A6"  src="        
        https://feed.mikle.com/widget/v2/156127/?id=fw-iframe156127&preloader-text=Cargando%E2%80%A6"></iframe>
    </div>
</div>
</template>

<script>
export default {
    name : 'NewsComponent',
    props : {
        titulo : String
    },
    data() {
        return {
            hash: "NEWC",
            title : null
        }
    },
    mounted(){
        
    },
}
</script>